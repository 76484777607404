import { useContext, useEffect, useState, KeyboardEvent, useCallback, useMemo } from 'react';
import { Grid, SwipeableDrawer } from '@mui/material';
import GridHeader from '../../components/grid-header/GridHeader';
import AlertDialog from '../../components/dialog/AlertDialog';
import { AdminContext } from '../../context/adminContext';
import { userInitialState } from '../../context/reducers/customerReducer';
import AdminForm from './AdminForm';
import listAdminsGridColumns from './ListAdminsGridColumns';
import FormTypesEnum from '../../enums/formTypes';
import RoleEnum from '../../enums/roles';
import { NotificationContext } from '../../context/notificationContext';
import { defaultPagination } from '../../utils/constants/defaults';
import { UserInterface } from '../../utils/types/user';
import { debounce } from '../../utils/lib';
import './Admins.scss';
import MrtDataTable from '../../components/mrt-data-table';
import { Pagination } from '../../utils/types/pagination';
import { MRT_PaginationState, MRT_SortingState } from 'material-react-table';

const defaultAttributesParams = [
  'id',
  'name',
  'lastName',
  'email',
  'phone',
  'createdAt',
  'status',
  'role'
];
const defaultOrderParams = ['createdAt:DESC'];
const defaultPaginationParams = {
  ...defaultPagination,
  attributes: defaultAttributesParams,
  order: defaultOrderParams
};

const ListAdmins = () => {
  const initialValues = userInitialState;

  const { getAdminsPaginated, deleteAdmin, resetState, state } = useContext(AdminContext);
  const { showAlert } = useContext(NotificationContext);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInterface>(initialValues);
  const [formType, setFormType] = useState<FormTypesEnum>(FormTypesEnum.CREATE);
  const [paginationParams, setPaginationParams] = useState<Pagination>(defaultPaginationParams);

  useEffect(() => {
    document.body.style.backgroundColor = '#E7E7E9';

    return () => {
      document.body.style.backgroundColor = '#FFF';
    };
  }, []);

  useEffect(() => {
    setSelectedUser(state.selected);
  }, [state.selected]);

  const onEditUser = async (user: UserInterface) => {
    setFormType(FormTypesEnum.EDIT);
    setSelectedUser(user);
    setOpenForm(!openForm);
  };

  const onDeleteUser = (user: UserInterface) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const columns = listAdminsGridColumns(onEditUser, onDeleteUser);

  const toggleDrawer = (isOpen: boolean) => (event: KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setFormType(FormTypesEnum.CREATE);
    resetState();
    setOpenForm(isOpen);
  };

  const handleSearch = debounce((inputVal) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      search: `name:${inputVal},email:${inputVal}`
    }));
  }, 500);

  useEffect(() => {
    listAdministators(paginationParams, openForm);
  }, [paginationParams, openForm]);

  const listAdministators = useCallback(
    (paginationParams: Pagination, openForm: boolean) => {
      if (!openForm) getAdminsPaginated(paginationParams);
    },
    [paginationParams, openForm]
  );

  const handlePaginationChange = (paginationState: MRT_PaginationState) => {
    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        page: paginationState.pageIndex + 1,
        perPage: paginationState.pageSize
      };
    });
  };

  const handleSortingChange = (sortingState: MRT_SortingState) => {
    const order = sortingState.length
      ? sortingState.map((sort) => `${sort.id}:${sort.desc ? 'DESC' : 'ASC'}`)
      : defaultOrderParams;

    setPaginationParams((prevParams) => {
      return {
        ...prevParams,
        order
      };
    });
  };

  const deleteAdminHandler = async () => {
    deleteAdmin(selectedUser.id, RoleEnum.ADMIN, showAlert)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setOpenDeleteDialog(false);
        getAdminsPaginated(paginationParams);
      });
  };

  const handlerCloseForm = () => {
    resetState();
    setSelectedUser(initialValues);
    setOpenForm(false);
  };

  return (
    <>
      <GridHeader
        searchValue={''}
        title={'Admins list'}
        buttonText={'New admin'}
        placeholder={'Search admin'}
        onChangeSearch={handleSearch}
        onClick={toggleDrawer(!openForm)}
      />

      <MrtDataTable
        isLoading={state?.isLoading}
        isError={Boolean(state?.error)}
        columns={columns}
        rows={state?.paginated.results}
        rowCount={state?.paginated.pagination.total}
        initialPagination={{
          pageIndex: paginationParams.page - 1,
          pageSize: paginationParams.perPage
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
      />

      <SwipeableDrawer
        anchor={'bottom'}
        open={openForm}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={swipeableDrawerStyle}>
        {openForm && (
          <AdminForm onClickClose={handlerCloseForm} admin={selectedUser} type={formType} />
        )}
      </SwipeableDrawer>

      {openDeleteDialog && (
        <AlertDialog
          disableButtons={state.isLoading}
          content={
            <Grid
              container
              direction="row"
              alignItems="center"
              margin={'auto'}
              justifyContent={'center'}
              textAlign={'center'}>
              <Grid item xs={10} sm={8.7} md={8.7} lg={8.7} xl={8.7}>
                <span style={modalTextStyle}>
                  Are you sure you want to remove <strong>{selectedUser.name}</strong> as
                  administrator?
                </span>
              </Grid>
            </Grid>
          }
          cancelText={'No, cancel'}
          approveText={'Yes, remove'}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onSave={deleteAdminHandler}
        />
      )}
    </>
  );
};

export default ListAdmins;

const modalTextStyle = { color: '#1F1F1F', fontSize: '14px' };
const swipeableDrawerStyle = {
  maxHeight: 'calc(100% - 64px)',
  '.MuiPaper-root': {
    background: '#fff',
    borderRadius: '8px 8px 0 0'
  }
};
