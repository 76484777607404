import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';

import { SignIn } from './pages/auth';

import NotFound from './pages/not-found/NotFound';
import NotAuthorized from './pages/not-authorized/NotAuthorized';
import InternalServerError from './pages/internal-server-error/InternalServerError';
import UnderMaintenance from './pages/UnderMaintenance';
import paths, { authPath, privatePath } from '../src/utils/constants/paths';
import './App.scss';
import AuthLayout from './containers/auth-layout/AuthLayout';
import Admins from './pages/admins/Admins';
import BlogEntries from './pages/blogs/BlogEntryList';
import BlogEntryForm from './pages/blogs/BlogEntryForm';
import Customers from './pages/customers/Customers';
import EditProfile from './pages/edit-profile/EditProfile';
import VesselEdit from './pages/vessels/VesselEdit';
import VesselSections from './pages/vessels/VesselSections';
import Vessels from './pages/vessels/Vessels';
import ChecklistTemplateList from './pages/form-templates/checklist/ChecklistTemplateList';
import CheckListTemplateForm from './pages/form-templates/checklist/CheckListTemplateForm';
import TankLevelsTemplateList from './pages/form-templates/tank-levels/TankLevelsTemplateList';
import TankLevelsTemplateForm from './pages/form-templates/tank-levels/TankLevelsTemplateForm';
import SystemInfoTemplateList from './pages/form-templates/system-info/SystemInfoTemplateList';
import SystemInfoTemplateForm from './pages/form-templates/system-info/SystemInfoTemplateForm';
import CheckLists from './pages/vessels/checklist/CheckList';
import ChecklistEdit from './pages/vessels/checklist/ChecklistEdit';
import TankLevelsList from './pages/vessels/tank-levels/TankLevelsList';
import TankLevelEdit from './pages/vessels/tank-levels/TankLevelEdit';
import UscgSafetyList from './pages/vessels/uscg-safety/UscgSafetyList';
import SystemInfoList from './pages/vessels/system-info/SystemInfoList';
import SystemInfoEdit from './pages/vessels/system-info/SystemInfoEdit';
import UpcomingServicesList from './pages/vessels/upcoming-services/UpcomingServicesList';
import ServiceRecordsList from './pages/vessels/service-records/ServiceRecordsList';
import DocumentsList from './pages/vessels/documents/DocumentsList';
import ChecklistHistoryList from './pages/vessels/checklist-history/ChecklistHistoryList';
import VesselMediaFileList from './pages/vessels/vessel-media/VesselMediaFileList';
import CrewRequestsList from './pages/vessels/crew-requests/CrewRequestsList';

import { Provider as UserProvider } from './context/adminContext';
import { Provider as CustomerProvider } from './context/customerContext';
import { Provider as VesselProvider } from './context/vesselContext';
import { Provider as BlogProvider } from './context/blogEntryContext';
import { Provider as ChecklistTemplateProvider } from './context/checklistTemplateContext';
import { Provider as ChecklistProvider } from './context/checklistContext';
import { Provider as TankLevelProvider } from './context/tankLevelContext';
import { Provider as TankLevelTemplateProvider } from './context/tankLevelTemplateContext';
import { Provider as UscgSafetyProvider } from './context/uscgSafetyContext';
import { Provider as SystemInfoProvider } from './context/systemInfoContext';
import { Provider as SystemInfoTemplateProvider } from './context/systemInfoTemplateContext';
import { Provider as UpcomingServiceProvider } from './context/upcomingServiceContext';
import { Provider as ServiceRecordProvider } from './context/serviceRecordContext';
import { Provider as DocumentProvider } from './context/documentContext';
import { Provider as VesselMediaFileProvider } from './context/vesselMediaFileContext';
import { Provider as CrewRequestProvider } from './context/crewRequestContext';
import { Provider as TemporalAlertProvider } from './context/notificationContext';
import { Provider as StorableAssetProvider } from './context/storableAssetContext';
import { Provider as MyProfileProvider } from './context/myProfileContext';
import PrivateLayout from './containers/private-layout/PrivateLayout';
import Loader from './components/loader/Loader';

const App = () => {
  return (
    <>
      <CssBaseline />
      <Authenticator.Provider>
        <Router>
          <TemporalAlertProvider>
            <Routes>
              <Route path={''} element={<Navigate to={paths.SIGN_IN.absolutePath} />} />

              <Route path={paths.PAGE_NOT_FOUND.slug} element={<NotFound />} />
              <Route path={paths.NOT_AUTHORIZED.slug} element={<NotAuthorized />} />
              <Route path={paths.INTERNAL_SERVER_ERROR.slug} element={<InternalServerError />} />
              <Route path={paths.UNDER_MAINTENANCE.slug} element={<UnderMaintenance />} />

              <Route path={authPath} element={<AuthLayout />}>
                <Route path={''} element={<Navigate to={paths.SIGN_IN.absolutePath} />} />

                <Route path={paths.SIGN_IN.slug} element={<SignIn />} />
              </Route>

              <Route path={privatePath} element={<PrivateLayout />}>
                <Route path={''} element={<Navigate to={paths.ADMINS.slug} />} />
                <Route
                  path={paths.VESSELS.slug}
                  element={
                    <VesselProvider>
                      <CustomerProvider>
                        <Vessels />
                      </CustomerProvider>
                    </VesselProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_SECTIONS.slug}
                  element={
                    <VesselProvider>
                      <CrewRequestProvider>
                        <ChecklistProvider>
                          <MyProfileProvider>
                            <VesselSections />
                          </MyProfileProvider>
                        </ChecklistProvider>
                      </CrewRequestProvider>
                    </VesselProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_EDIT.slug}
                  element={
                    <VesselProvider>
                      <StorableAssetProvider>
                        <CustomerProvider>
                          <VesselEdit />
                        </CustomerProvider>
                      </StorableAssetProvider>
                    </VesselProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_CHECKLIST.slug}
                  element={
                    <ChecklistTemplateProvider>
                      <ChecklistProvider>
                        <VesselProvider>
                          <CheckLists />
                        </VesselProvider>
                      </ChecklistProvider>
                    </ChecklistTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_CHECKLIST_EDIT.slug}
                  element={
                    <ChecklistTemplateProvider>
                      <ChecklistProvider>
                        <VesselProvider>
                          <ChecklistEdit />
                        </VesselProvider>
                      </ChecklistProvider>
                    </ChecklistTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_TANK_LEVELS.slug}
                  element={
                    <TankLevelTemplateProvider>
                      <TankLevelProvider>
                        <VesselProvider>
                          <TankLevelsList />
                        </VesselProvider>
                      </TankLevelProvider>
                    </TankLevelTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_TANK_LEVEL_EDIT.slug}
                  element={
                    <TankLevelTemplateProvider>
                      <TankLevelProvider>
                        <VesselProvider>
                          <TankLevelEdit />
                        </VesselProvider>
                      </TankLevelProvider>
                    </TankLevelTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_USCG_SAFETY_LIST.slug}
                  element={
                    <UscgSafetyProvider>
                      <VesselProvider>
                        <UscgSafetyList />
                      </VesselProvider>
                    </UscgSafetyProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_SYSTEM_INFO_LIST.slug}
                  element={
                    <SystemInfoTemplateProvider>
                      <SystemInfoProvider>
                        <VesselProvider>
                          <SystemInfoList />
                        </VesselProvider>
                      </SystemInfoProvider>
                    </SystemInfoTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_SYSTEM_INFO_EDIT.slug}
                  element={
                    <SystemInfoTemplateProvider>
                      <SystemInfoProvider>
                        <VesselProvider>
                          <SystemInfoEdit />
                        </VesselProvider>
                      </SystemInfoProvider>
                    </SystemInfoTemplateProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_UPCOMING_SERVICES_LIST.slug}
                  element={
                    <UpcomingServiceProvider>
                      <VesselProvider>
                        <UpcomingServicesList />
                      </VesselProvider>
                    </UpcomingServiceProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_SERVICE_RECORDS_LIST.slug}
                  element={
                    <StorableAssetProvider>
                      <ServiceRecordProvider>
                        <VesselProvider>
                          <ServiceRecordsList />
                        </VesselProvider>
                      </ServiceRecordProvider>
                    </StorableAssetProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_DOCUMENTS_LIST.slug}
                  element={
                    <StorableAssetProvider>
                      <DocumentProvider>
                        <VesselProvider>
                          <DocumentsList />
                        </VesselProvider>
                      </DocumentProvider>
                    </StorableAssetProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_CHECKLIST_HISTORY_LIST.slug}
                  element={
                    <StorableAssetProvider>
                      <ChecklistProvider>
                        <VesselProvider>
                          <ChecklistHistoryList />
                        </VesselProvider>
                      </ChecklistProvider>
                    </StorableAssetProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_VESSEL_MEDIA_FILES_LIST.slug}
                  element={
                    <StorableAssetProvider>
                      <VesselMediaFileProvider>
                        <VesselProvider>
                          <VesselMediaFileList />
                        </VesselProvider>
                      </VesselMediaFileProvider>
                    </StorableAssetProvider>
                  }
                />
                <Route
                  path={paths.VESSEL_CREW_REQUESTS.slug}
                  element={
                    <CrewRequestProvider>
                      <VesselProvider>
                        <CrewRequestsList />
                      </VesselProvider>
                    </CrewRequestProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_CHECKLIST.slug}
                  element={
                    <ChecklistTemplateProvider>
                      <ChecklistTemplateList />
                    </ChecklistTemplateProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_CHECKLIST_FORM.slug}
                  element={
                    <ChecklistTemplateProvider>
                      <CheckListTemplateForm />
                    </ChecklistTemplateProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_TANK_LEVELS.slug}
                  element={
                    <TankLevelTemplateProvider>
                      <TankLevelsTemplateList />
                    </TankLevelTemplateProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_TANK_LEVELS_FORM.slug}
                  element={
                    <TankLevelTemplateProvider>
                      <TankLevelsTemplateForm />
                    </TankLevelTemplateProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_SYSTEMS_INFO.slug}
                  element={
                    <SystemInfoTemplateProvider>
                      <SystemInfoTemplateList />
                    </SystemInfoTemplateProvider>
                  }
                />
                <Route
                  path={paths.TEMPLATE_SYSTEM_INFO_FORM.slug}
                  element={
                    <SystemInfoTemplateProvider>
                      <SystemInfoTemplateForm />
                    </SystemInfoTemplateProvider>
                  }
                />
                <Route
                  path={paths.CUSTOMERS.slug}
                  element={
                    <VesselProvider>
                      <CustomerProvider>
                        <MyProfileProvider>
                          <Customers />
                        </MyProfileProvider>
                      </CustomerProvider>
                    </VesselProvider>
                  }
                />
                <Route
                  path={paths.ADMINS.slug}
                  element={
                    <VesselProvider>
                      <UserProvider>
                        <MyProfileProvider>
                          <Admins />
                        </MyProfileProvider>
                      </UserProvider>
                    </VesselProvider>
                  }
                />
                <Route
                  path={paths.BLOG_ENTRIES.slug}
                  element={
                    <BlogProvider>
                      <BlogEntries />
                    </BlogProvider>
                  }
                />
                <Route
                  path={paths.BLOG_ENTRY_FORM.slug}
                  element={
                    <StorableAssetProvider>
                      <BlogProvider>
                        <BlogEntryForm />
                      </BlogProvider>
                    </StorableAssetProvider>
                  }
                />
                <Route
                  path={paths.EDIT_PROFILE.slug}
                  element={
                    <MyProfileProvider>
                      <EditProfile />
                    </MyProfileProvider>
                  }
                />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </TemporalAlertProvider>
          <Loader />
        </Router>
      </Authenticator.Provider>
    </>
  );
};

export default App;
