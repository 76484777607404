import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { VesselInterface } from '../../utils/types/vessel';
import ServiceCardList from '../../components/service-card/ServiceCardList';
import TitlePage from '../../components/title-page/TitlePage';
import './Vessels.scss';
import { VesselContext } from '../../context/vesselContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { MyProfileContext } from '../../context/myProfileContext';
import { UserBasicInfo } from '../../utils/types/user';
import { myProfileInitialState } from '../../context/reducers/myProfileReducer';

export type Notification = {
  service: string;
  count: number;
};

const VesselSections: FC = (): ReactElement => {
  const query = useQueryParams();
  const { viewVessel, state } = useContext(VesselContext);
  const [vesselId] = useState<string>(query.get('id') || '');
  const [vessel, setVessel] = useState<VesselInterface | undefined>(undefined);
  const { viewMyProfileInfo, state: myProfileState } = useContext(MyProfileContext);

  const [myProfile, setMyProfile] = useState<UserBasicInfo>(myProfileInitialState);

  useEffect(() => {
    if (
      !myProfileState.viewInfo.error &&
      myProfileState.viewInfo.done &&
      myProfileState.viewInfo.data
    ) {
      setMyProfile(myProfileState.viewInfo.data);
    }
  }, [myProfileState.viewInfo]);

  useEffect(() => {
    viewMyProfileInfo();
    if (vesselId) viewVessel(vesselId, { join: 'owner' });
  }, []);

  useEffect(() => {
    if (state.view.data) setVessel(state.view.data);
  }, [state.view]);

  return (
    <>
      <div className="vessel-sections-title">
        <TitlePage leftContainer={<div className="vessel-grid">{vessel?.name}</div>} />
      </div>
      {vessel && myProfile && (
        <ServiceCardList vesselId={vesselId} role={myProfile.role || 'operator'} />
      )}
    </>
  );
};

export default VesselSections;
