import { FC, ReactElement } from 'react';
import { Button, Grid, useMediaQuery } from '@mui/material';
import './SwipeableDrawerHeader.scss';
import { capitalizeFirstLetter } from '../../utils/lib';

interface VesselFormArgs {
  title: string;
  icon: ReactElement;
  onClickClose: () => void;
  onClickSave: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disableButtons?: boolean;
}

const gridItem = <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />;

const SwipeableDrawerHeader: FC<VesselFormArgs> = ({
  title,
  icon,
  onClickClose,
  onClickSave,
  disableButtons = false
}): ReactElement => {
  const largeScreen = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const actions = (
    <>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
        <Button
          variant="outlined"
          type="button"
          onClick={onClickClose}
          className="actionButton"
          style={largeScreen ? {} : closeButtonStyle}
          disabled={disableButtons}>
          Close
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
        <Button
          variant="contained"
          type="button"
          onClick={onClickSave}
          className="actionButton"
          disabled={disableButtons}>
          Save
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid item xs={10} sm={8} md={7} lg={7} xl={7}>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        padding={'61px 0 27px 0'}
        alignItems={'center'}>
        <Grid item xs={2} sm={1} md={0.8} lg={0.5} xl={0.3}>
          {icon}
        </Grid>
        <Grid item xs={9} sm={10} md={3} lg={3} xl={3}>
          <p className="title">{capitalizeFirstLetter(title)}</p>
        </Grid>
        {gridItem}
        {gridItem}
        {gridItem}
        {gridItem}
        {actions}
      </Grid>
    </Grid>
  );
};

export default SwipeableDrawerHeader;

const closeButtonStyle = { marginBottom: '20px' };
