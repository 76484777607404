import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { AdminContext } from '../../context/adminContext';
import UserForm from '../../components/user-form/UserForm';
import RoleEnum from '../../enums/roles';
import FormTypesEnum from '../../enums/formTypes';
import { createAdminSchema, updateAdminSchema } from './schema';
import { NotificationContext } from '../../context/notificationContext';
import { UserInterface } from '../../utils/types/user';
import UpdatePasswordForm from '../../components/update-password-form/UpdatePasswordForm';
import './AdminForm.scss';
import React from 'react';

interface AdminFormArgs {
  onClickClose: () => void;
  admin: UserInterface;
  type: FormTypesEnum;
}

const AdminForm: FC<AdminFormArgs> = ({ onClickClose, admin, type }): ReactElement => {
  const { createAdmin, editAdmin, updatePassword, state } = useContext(AdminContext);
  const { showAlert } = useContext(NotificationContext);
  const [schemaForm, setSchemaForm] = useState(createAdminSchema);

  useEffect(() => {
    if (type === FormTypesEnum.EDIT) setSchemaForm(updateAdminSchema);
    else if (type === FormTypesEnum.CREATE) setSchemaForm(createAdminSchema);
  }, [type]);

  const handleSubmit = (form: UserInterface) => {
    switch (type) {
      case FormTypesEnum.CREATE:
        createAdmin(
          {
            status: form.status,
            name: form.name,
            lastName: form.lastName,
            email: form.email,
            role: form.role,
            phone: form.phone,
            vesselsAssigned: form.assignedVessels?.map((vessel) => vessel.id) || []
          },
          showAlert
        );
        break;
      case FormTypesEnum.EDIT:
        editAdmin(
          {
            id: admin.id,
            status: form.status,
            name: form.name,
            lastName: form.lastName,
            email: form.email,
            phone: form.phone,
            role: form.role,
            vesselsAssigned: form.assignedVessels?.map((vessel) => vessel.id) || []
          },
          showAlert
        );
        break;
      default:
        throw new Error('Action not supported');
    }
  };

  const handleUpdatePasswordFormSubmit = async (password: string) => {
    await updatePassword(
      {
        userId: admin.id,
        password
      },
      showAlert
    );

    onClickClose();
  };

  return (
    <>
      <UserForm
        onClickClose={onClickClose}
        onSubmitForm={handleSubmit}
        user={admin}
        schema={schemaForm}
        userState={state}
        type={type}
      />

      {type === FormTypesEnum.EDIT && (
        <UpdatePasswordForm
          variant="wide"
          onSubmit={handleUpdatePasswordFormSubmit}
          containerBreakpoints={{ xs: 10, sm: 8, md: 7, lg: 7, xl: 7 }}
          buttonBreakpoints={{ xs: 10, sm: 8, md: 7, lg: 7, xl: 7 }}
          buttonText={'Save new password'}
        />
      )}
    </>
  );
};

export default AdminForm;
