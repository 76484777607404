import Joi from 'joi';

export const passwordField = Joi.string()
  .label('Password')
  .pattern(
    new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&\\/,><\\’:;|_~`])\\S{8,99}$'
    )
  )
  .message(
    'The provided password must contains at least 8 characters, 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter'
  );

export const confirmPasswordField = Joi.any()
  .label('Confirm Password')
  .equal(Joi.ref('password'))
  .messages({ 'any.only': '{{#label}} does not match' });

export const signInSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: passwordField
}).options({
  abortEarly: false
});

export const requestPasswordResetSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
}).options({
  abortEarly: false
});

export const requireNewPasswordShema = Joi.object({
  username: Joi.string().required(),
  code: Joi.string().required(),
  password: passwordField,
  confirmPassword: Joi.any()
    .label('Confirm Password')
    .equal(Joi.ref('password'))
    .messages({ 'any.only': '{{#label}} does not match' })
    .required()
}).options({
  abortEarly: false
});

export const userFormSchema: Joi.PartialSchemaMap = {
  id: Joi.string().optional().allow(''),
  name: Joi.string().required(),
  createdAt: Joi.string().optional().allow(''),
  status: Joi.string().required().allow('ENABLED', 'DISABLED'),
  role: Joi.string().required().allow('admin', 'operator'),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phone: Joi.string()
    .pattern(/^[+]{1}?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,9}$/)
    .required()
    .messages({
      'string.pattern.base':
        'It must start with a "+" followed by your country code and phone number. The total number of digits (excluding the "+") should be between 9 and 15.'
    })
};

export const vesselFormSchema = Joi.object({
  name: Joi.string().required(),
  user_id: Joi.string().required(),
  hullNumber: Joi.string().required(),
  hight: Joi.string().optional().allow(''),
  beam: Joi.string().optional().allow(''),
  draft: Joi.string().optional().allow(''),
  length: Joi.string().optional().allow(''),
  location: Joi.string().optional().allow(''),
  notes: Joi.string().optional().allow(''),
  active: Joi.boolean().required(),
  storeable_asset_id: Joi.string().uuid().optional().allow(null, '')
}).options({
  abortEarly: false
});

export const groupFieldSchema = Joi.object({
  fieldName: Joi.string().required(),
  fieldType: Joi.string().required().valid('text', 'number', 'calendar', 'checkBox')
}).options({
  abortEarly: false
});

export const groupNameSchema = Joi.object({
  name: Joi.string().required()
}).options({
  abortEarly: false
});
