import { Reducer } from 'react';
import AdminActionsTypes from '../actions/types/admin';
import { AdminActionInterface } from '../../utils/types/admin';
import { ActionInterface, ErrorInterface, PaginatedListInterface } from '../../utils/types/common';
import { errorInitialState, getInitialPaginationState } from '../../utils/constants/states';
import { PasswordFormInterface, UserInterface, UsersStateInterface } from '../../utils/types/user';
import RoleEnum from '../../enums/roles';

export const actionInitialState: ActionInterface = {
  loading: false,
  error: undefined,
  done: false
};

const initialPaginationState = getInitialPaginationState<UserInterface>();

export const userInitialState: UserInterface = {
  id: '',
  status: 'DISABLED',
  role: RoleEnum.OPERATOR,
  name: '',
  lastName: '',
  email: '',
  phone: '',
  createdAt: '',
  assignedVessels: []
};

export const updatePasswordInitialState: PasswordFormInterface = {
  userId: '',
  password: '',
  confirmPassword: ''
};

export const initialState: UsersStateInterface = {
  listSignUpRequests: initialPaginationState,
  approveSignUpRequest: actionInitialState,
  rejectSignUpRequest: actionInitialState,
  paginated: initialPaginationState,
  selected: userInitialState,
  isLoading: false,
  error: errorInitialState
};

export const reducer: Reducer<UsersStateInterface, AdminActionInterface> = (
  state = initialState,
  action: AdminActionInterface
): UsersStateInterface => {
  switch (action.type) {
    case AdminActionsTypes.CREATE_ADMIN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case AdminActionsTypes.CREATE_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.FETCH_ADMINS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paginated: action.payload as PaginatedListInterface<UserInterface>,
        error: errorInitialState
      };
    case AdminActionsTypes.FETCH_ADMINS_ERROR:
      return {
        ...state,
        isLoading: false,
        listSignUpRequests: initialPaginationState,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listSignUpRequests: action.payload as PaginatedListInterface<UserInterface>,
        error: errorInitialState
      };
    case AdminActionsTypes.LIST_ADMIN_SIGN_UP_REQUESTS_ERROR:
      return {
        ...state,
        isLoading: false,
        listSignUpRequests: initialPaginationState,
        error: action.payload as ErrorInterface
      };

    case AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS:
      return {
        ...state,
        approveSignUpRequest: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        approveSignUpRequest: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case AdminActionsTypes.APPROVE_ADMIN_SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        approveSignUpRequest: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_IN_PROGRESS:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: true,
          error: undefined,
          done: false
        }
      };
    case AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: false,
          error: undefined,
          done: true
        }
      };
    case AdminActionsTypes.REJECT_ADMIN_SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        rejectSignUpRequest: {
          loading: false,
          error: action.payload as ErrorInterface,
          done: false
        }
      };

    case AdminActionsTypes.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selected: action.payload as unknown as UserInterface
      };
    case AdminActionsTypes.FETCH_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        selected: initialState.selected,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.DELETE_ADMIN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case AdminActionsTypes.DELETE_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.EDIT_ADMIN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case AdminActionsTypes.EDIT_ADMIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.EDIT_ADMIN_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true
      };
    case AdminActionsTypes.EDIT_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    case AdminActionsTypes.EDIT_ADMIN_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload as ErrorInterface
      };
    case AdminActionsTypes.RESET_STATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: errorInitialState
      };
    default:
      return state;
  }
};
