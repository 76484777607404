import {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useContext
} from 'react';
import Joi from 'joi';
import { Checkbox, FormHelperText, FormLabel, Grid } from '@mui/material';
import { ReactComponent as BriefcaseIcon } from '../../assets/images/wrapped-briefcase.svg';
import SwipeableDrawerHeader from '../../components/swipeable-drawer-header/SwipeableDrawerHeader';
import FieldContainerExternal from '../../components/field-container/FieldContainer';
import VesselsTagsInput from '../../components/vessels-tags-input/VesselsTagsInput';
import UserProfile from '../user-profile/UserProfile';
import { validateForm } from '../../utils/form';
import { userInitialState } from '../../context/reducers/customerReducer';
import './UserForm.scss';
import FormTypesEnum from '../../enums/formTypes';
import { UserInterface, UserBasicInfo, UsersStateInterface } from '../../utils/types/user';
import RoleEnum from '../../enums/roles';
import { myProfileInitialState } from '../../context/reducers/myProfileReducer';
import { MyProfileContext } from '../../context/myProfileContext';

interface UserFormArgs {
  onClickClose: () => void;
  onSubmitForm: (form: UserInterface) => void;
  user: UserInterface;
  schema: Joi.ObjectSchema<any>;
  userState: UsersStateInterface;
  type: FormTypesEnum;
}

const FieldContainer: FC<PropsWithChildren> = ({ children }): ReactElement => {
  return <FieldContainerExternal children={children} xs={10} sm={8} md={7} lg={7} xl={7} />;
};

const UserForm: FC<UserFormArgs> = ({
  onClickClose,
  onSubmitForm: submitForm,
  user,
  schema,
  userState,
  type
}): ReactElement => {
  const ref = useRef<{ getUserBasicInfo(): void }>(null);
  const [form, setForm] = useState<UserInterface>(user);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState(userInitialState);
  const [wasLoading, setWasLoading] = useState(userState.isLoading);

  const [role, setRole] = useState(user?.role);
  const [isAdmin, setIsAdmin] = useState(role === RoleEnum.ADMIN);

  const { viewMyProfileInfo, state: myProfileState } = useContext(MyProfileContext);

  const [myProfile, setMyProfile] = useState<UserBasicInfo>(myProfileInitialState);

  useEffect(() => {
    if (
      !myProfileState.viewInfo.error &&
      myProfileState.viewInfo.done &&
      myProfileState.viewInfo.data
    ) {
      setMyProfile(myProfileState.viewInfo.data);
    }
  }, [myProfileState.viewInfo]);

  useEffect(() => {
    viewMyProfileInfo();
  }, []);

  useEffect(() => {
    setForm(user);
  }, [user]);

  useEffect(() => {
    if (userState.error.text) setFormErrorMessage(userState.error.text);
    else setFormErrorMessage('');
  }, [userState.error]);

  useEffect(() => {
    if (wasLoading && !userState.isLoading && !userState.error.text) {
      onClickClose();
    }
    setWasLoading(userState.isLoading);
  }, [userState.isLoading]);

  const handleCheckBoxStateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      status: event.target?.checked ? 'ENABLED' : 'DISABLED'
    }));
  };

  const handleIsAdminChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(event.target?.checked);
    setRole(event.target?.checked ? RoleEnum.ADMIN : RoleEnum.OPERATOR);

    setForm((prevState) => ({
      ...prevState,
      role: event.target?.checked ? RoleEnum.ADMIN : RoleEnum.OPERATOR
    }));
  };

  const onChangeAssignedVesselsTags = (value: any) => {
    setForm((prevState) => ({
      ...prevState,
      assignedVessels: value
    }));
  };

  const handleSubmit = (userBasicInfo: UserBasicInfo) => {
    const newForm: UserInterface = {
      ...form,
      name: userBasicInfo.name,
      lastName: userBasicInfo.lastName,
      email: userBasicInfo.email,
      phone: userBasicInfo.phone,
      status: form.status || 'DISABLED',
      role: form.role
    };

    const { errorSummary } = validateForm(schema, newForm);

    setValidationErrors(errorSummary);
    if (!Object.values(errorSummary).length) {
      submitForm(newForm);
    }
  };

  const handleClose = () => {
    onClickClose();
    setForm(user);
    setValidationErrors(userInitialState);
  };

  const onClickSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (ref.current) ref.current.getUserBasicInfo();
  };

  const fields = (
    <>
      <UserProfile
        ref={ref}
        user={user}
        validationErrors={validationErrors}
        sendForm={handleSubmit}
      />

      {role !== RoleEnum.CUSTOMER && (
        <FieldContainer>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VesselsTagsInput
              title="Assign vessels"
              value={form.assignedVessels || []}
              onChange={onChangeAssignedVesselsTags}
            />
          </Grid>
        </FieldContainer>
      )}

      <Grid item xs={10} sm={8} md={7} lg={7} xl={7} style={checkBoxes}>
        <div>
          <Checkbox
            style={checkBoxStyle}
            checked={form.status === 'ENABLED'}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckBoxStateChange(e)}
          />
          <FormLabel component="label" className={'regular'}>
            Active
          </FormLabel>
        </div>
        {myProfile.role === RoleEnum.ADMIN && role !== RoleEnum.CUSTOMER && (
          <div>
            <Checkbox
              style={checkBoxStyle}
              checked={isAdmin}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleIsAdminChange(e)}
            />
            <FormLabel component="label" className={'regular'}>
              Is Admin
            </FormLabel>
          </div>
        )}
      </Grid>
    </>
  );

  const errors = (
    <FieldContainer>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormHelperText error>{formErrorMessage}</FormHelperText>
      </Grid>
    </FieldContainer>
  );

  return (
    <div className="container-user-form">
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        margin={'auto'}
        justifyContent={'center'}>
        <SwipeableDrawerHeader
          onClickClose={handleClose}
          onClickSave={onClickSave}
          disableButtons={userState.isLoading}
          title={`${role}`}
          icon={<BriefcaseIcon className={'user-icon'} />}
        />
        {fields}
        {errors}
      </Grid>
    </div>
  );
};

export default UserForm;

const checkBoxStyle = { paddingLeft: '0px' };
const checkBoxes = { display: 'flex', itemAlign: 'center', gap: '10px', paddingTop: '10px' };
